import * as Sentry from '@sentry/nextjs';
import config from './src/config';
import Environment from './src/models/Environment';

const { NEXT_PUBLIC_SENTRY_DSN_KEY } = process.env;

const initSentry = () => {
  Sentry.init({
    dsn: NEXT_PUBLIC_SENTRY_DSN_KEY,
    environment: config.env,
    debug: config.env !== Environment.PROD,
    attachStacktrace: true,
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.setTag('service', 'baemin-com');
  Sentry.setTag('role', 'baemin-com-web');
};

export default initSentry;
