import Config from 'models/Config';
import Environment from 'models/Environment';

const NEXT_PUBLIC_NODE_ENV =
  process.env.NEXT_PUBLIC_NODE_ENV || Environment.DEVELOP;

const config: Record<Environment, Config> = {
  [Environment.LOCAL]: {
    env: Environment.LOCAL,
    wwwApiHost: '',
    familyAccountHost: 'https://baemin-family.betabaemin.com',
    internalWebviewHost: 'https://internal.dev.baemin.com',
    actionScheme: 'baemin-action-beta',
    appScheme: 'baemin-beta',
    host: 'https://beta.baemin.com',
    payPlatformHost: 'https://pay-platform.beta.baemin.com',
    ceoTutorialUrl:
      'https://order-take-static.beta.baemin.com/order-relay-tutorial/mobile',
    payCdnHost: 'https://pay-cdn.beta.baemin.com',
  },
  [Environment.DEVELOP]: {
    env: Environment.DEVELOP,
    wwwApiHost: 'https://www-api.beta.baemin.com',
    familyAccountHost: 'https://baemin-family.betabaemin.com',
    internalWebviewHost: 'https://internal.dev.baemin.com',
    actionScheme: 'baemin-action-beta',
    appScheme: 'baemin-beta',
    host: 'https://beta.baemin.com',
    payPlatformHost: 'https://pay-platform.beta.baemin.com',
    ceoTutorialUrl:
      'https://order-take-static.beta.baemin.com/order-relay-tutorial/mobile',
    payCdnHost: 'https://pay-cdn.beta.baemin.com',
  },
  [Environment.PROD]: {
    env: Environment.PROD,
    wwwApiHost: 'https://www-api.baemin.com',
    familyAccountHost: 'https://baemin-family.baemin.com',
    internalWebviewHost: 'https://internal.baemin.com',
    actionScheme: 'baemin-action',
    appScheme: 'baemin',
    host: 'https://www.baemin.com',
    payPlatformHost: 'https://pay-platform.baemin.com',
    ceoTutorialUrl:
      'https://order-take-static.baemin.com/order-relay-tutorial/mobile',
    payCdnHost: 'https://pay-cdn.baemin.com',
  },
};

const serverConfig = {
  NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION:
    process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION || '',
  NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV || Environment.DEVELOP,
  NEXT_PUBLIC_GA_TRACKING_CODE: process.env.NEXT_PUBLIC_GA_TRACKING_CODE || '',
  NEXT_PUBLIC_NAVER_TRACKING_CODE:
    process.env.NEXT_PUBLIC_NAVER_TRACKING_CODE || '',
  NEXT_PUBLIC_NEWSLETTER_GA_TRACKING_CODE:
    process.env.NEXT_PUBLIC_NEWSLETTER_GA_TRACKING_CODE || '',
};

const environmentConfig =
  config[NEXT_PUBLIC_NODE_ENV.toLowerCase() as Environment];

const combinedConfig = Object.assign(environmentConfig, serverConfig);

export default combinedConfig;
